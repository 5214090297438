import Header from "./Header";
import Navigation from "./Navigation";
import Services from "./Services";
import Portfolio from "./Portfolio";
import About from "./About";
import Team from "./Team";
import Clients from "./Clients";
import Contact from "./Contact";
import Footer from "./Footer";
import PortfolioModal from "./PortfolioModal";
import { Routes, Route} from "react-router-dom";

function App() {
  return (
    <div>
      <Navigation/>
      <Routes>
          <Route path="/" element={<Header />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contact" element={<Contact />}/>
      </Routes>
      <Clients/>
      {/*<Navigation/>
      <Header/>
      <Services/>
      <Portfolio/>
      <About/>
      <Team/>
      <Clients/>
      <Contact/>
      <Footer/>
      <PortfolioModal/>*/}
    </div>
  );
}

export default App;
